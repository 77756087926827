<template>
  <div class="car-offer">
     <div class="mobile-logo">
      <img class="mobile-logo" src="../assets/DM-Logo.png">
    </div>
    <div class="filter-wrapper-mobile">
      <div class="home-title"
        v-if="totalOffer.length !== 0">
        Filter
      </div>
      <div class="dropdown-wrapper">
        <div class="label">
          Marka vozila
        </div>
        <Dropdown
          class="dropdown-component"
          :options="carBrandsUniqueFilter"
          v-on:selected="validateSelection"
          :disabled="false"
          name="zipcode"
          :maxItem="10"
          placeholder="Odaberi...">
      </Dropdown>
      </div>
    </div>
    <div class="cars-wrapper">
      <div class="home-title"
        v-if="totalOffer.length !== 0">
        Ponuda vozila
      </div>
      <div class="cars-offer-wrapper">
        <div class="car-wrapper"
          v-for="car in totalOffer"
          :key="car.id"
          @click="carDetails(car)">
          <Car
            :carData="car.data"
            v-if="car.brand === brandSelected || brandSelected === 'Sve'">
          </Car>
        </div>
      </div>
    </div>
    <div class="filter-wrapper">
      <div class="home-title"
        v-if="totalOffer.length !== 0">
        Filter
      </div>
      <div class="dropdown-wrapper">
        <div class="label">
          Marka vozila
        </div>
        <Dropdown
          class="dropdown-component"
          :options="carBrandsUniqueFilter"
          v-on:selected="validateSelection"
          :disabled="false"
          name="zipcode"
          :maxItem="10"
          placeholder="Odaberi...">
      </Dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { each } from 'lodash';
import Dropdown from 'vue-simple-search-dropdown';
import { mapGetters, mapMutations } from 'vuex';
import Car from '../components/LatestCar.vue';

export default {
  name: 'CarOffer',
  computed: {
    ...mapGetters([
      'contactDetails',
      'workingHours',
      'address',
      'getCarOffer',
      'getTotalArticles',
      'getCarOfferTotalPages',
    ]),
  },
  components: {
    Car,
    Dropdown,
  },
  data() {
    return {
      totalOffer: [],
      carBrands: [],
      carBrandsUnique: [],
      carBrandsUniqueFilter: [],
      brandSelected: 'Sve',
    };
  },
  methods: {
    ...mapMutations([
      'setCarOffer',
      'setTotalArticles',
      'setCarOfferTotal',
    ]),
    validateSelection(event) {
      this.brandSelected = event.name;
    },
    carDetails(carData) {
      this.$router.push({ path: `/ponuda-vozila/${carData.data.id}`, name: 'CarDetails', params: { carId: carData.data.id, carDetails: carData } });
    },
  },
  mounted() {
    console.log('TOTAL OFFER:', this.getCarOfferTotalPages);
    each(this.getCarOfferTotalPages, (page) => {
      each(page.artikli, (car) => {
        const carData = {
          data: null,
          brand: null,
        };
        carData.data = car;
        each(car.polja, (carBrand) => {
          if (carBrand.naziv === 'Proizvodjac') {
            carData.brand = carBrand.vrijednost;
            this.carBrands.push(carBrand.vrijednost);
          }
        });
        this.totalOffer.push(carData);
        this.carBrandsUnique = [...new Set(this.carBrands)];
        this.carBrandsUniqueFilter = [];
        for (let i = 0; i < this.carBrandsUnique.length; i += 1) {
          this.carBrandsUniqueFilter.push({ id: `V${i}`, name: this.carBrandsUnique[i] });
        }
        this.carBrandsUniqueFilter.unshift({ id: 99, name: 'Sve' });
      });
    });
    console.log('TOTAL OFFER:', this.getCarOfferTotalPages);
    this.brandSelected = 'Sve';
  },
};
</script>

<style lang="scss">
.car-offer {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  .filter-wrapper-mobile {
    display: none;
    .home-title {
      font-family: 'Montserrat', sans-serif;
      margin-left: 3px;
      background-color: #ffffff;
      margin-top: 30px;
      padding: 15px;
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 10px 10px 0 0;
    }
    .dropdown-wrapper {
      margin-left: 3px;
      background-color: #ffffff;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      .label {
        text-align: left;
        padding-top: 5px;
        padding-left: 5px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
      }
      .dropdown-component {
        padding: 0px 5px 5px 5px;
      }
    }
  }
  .mobile-logo {
    display: none;
    text-align: left;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    height: 25px;
  }
  .cars-wrapper {
    max-width: 960px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .home-title {
      font-family: 'Montserrat', sans-serif;
      background-color: #ffffff;
      margin-top: 30px;
      padding: 15px;
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 10px 10px 0 0;
    }
    .cars-offer-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }
  }
  .filter-wrapper {
    max-width: 240px;
    width: 100%;
    .home-title {
      font-family: 'Montserrat', sans-serif;
      margin-left: 3px;
      background-color: #ffffff;
      margin-top: 30px;
      padding: 15px;
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 10px 10px 0 0;
    }
    .dropdown-wrapper {
      margin-left: 3px;
      background-color: #ffffff;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      .label {
        text-align: left;
        padding-top: 5px;
        padding-left: 5px;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
      }
      .dropdown-component {
        padding: 0px 5px 5px 5px;
      }
    }
  }
}
@media screen and (max-width: 940px) {
  .car-offer {
    display: flex;
    flex-direction: column;
    .filter-wrapper-mobile {
      display: block;
    }
    .filter-wrapper {
      display: none;
    }
    .mobile-logo {
      display: block;
    }
  }
}
</style>
